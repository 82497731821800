import { HttpMethods } from '../enums';
import { QueryKeys, QueryError } from '../queries';

import { useCustomMutation } from '.';

interface FederalProformaRow {
  fileId: string;
}

interface DeleteFederalProformaFiles {
  tobeDeletedRows: FederalProformaRow[];
}

export const useMutationDeleteFederalProformaFiles = () =>
  useCustomMutation<null, QueryError, DeleteFederalProformaFiles>({
    handleData: ({ tobeDeletedRows }) => ({
      url: `/api/efile/federal-proforma-processes/delete-files`,
      method: HttpMethods.DELETE,
      body: {
        tobeDeletedRows,
      },
    }),
    resetKey: QueryKeys.FederalProforma.FerderalFileDelete,
    successMessage: 'Delete federal proforma files successfully',
    errorMessage: 'Delete federal proforma files failed',
  });
