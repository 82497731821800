import { httpGet, httpGetAndParse, httpPost, httpPutAndParse } from '../../utils/fetchService';

export const globalSearch = ({ query, objectIdsWithLimit }) =>
  httpGetAndParse({
    route: `/api/object-data/global-search?query=${encodeURIComponent(
      query,
    )}&objectIdsWithLimit=${encodeURI(JSON.stringify(objectIdsWithLimit))}`,
    errorMessage: 'Fetching filterable data items with values by object id failed',
  });

export const fetchTaxYears = () =>
  httpGetAndParse({
    route: `/api/shared/tax-years`,
    errorMessage: 'Fetching tax years failed',
  });

export const fetchPeriods = taxYear =>
  httpGetAndParse({
    route: `/api/shared/periods/${taxYear}`,
    errorMessage: 'Fetching periods failed',
  });

export const fetchConsolidations = (taxYear, period) =>
  httpGetAndParse({
    route: `/api/shared/consolidations/${taxYear}/${period}`,
    errorMessage: 'Fetching consolidations failed',
  });

export const fetchEntities = (taxYear, period) =>
  httpGetAndParse({
    route: `/api/shared/entities/${taxYear}/${period}`,
    errorMessage: 'Fetching entities failed',
  });

export const fetchTrgReports = () =>
  httpGetAndParse({
    route: `/api/shared/trg-reports`,
    errorMessage: 'Fetching TRG reports failed',
  });

export const fetchLockIndicator = ({
  taxYear,
  period,
  businessEntityId,
  jurisdictionId,
  filingTypeId = 'SEPARATE',
}) =>
  httpGetAndParse({
    route: `/api/shared/${taxYear}/${period}/${filingTypeId}/${businessEntityId}/${jurisdictionId}/lock-indicator`,
    errorMessage: 'Fetching lock indicator failed',
  });

export const loadAllFormDataForSeparate = ({ taxYear, period, entityId, jurisdictionId }) =>
  httpPost({
    route: '/api/shared/load-all-form-data-for-separate',
    errorMessage: 'Failed to load all form data for separate entity',
    body: {
      taxYear,
      period,
      entityId,
      jurisdictionId,
    },
  });

export const loadAllFormDataForConsolidated = ({ taxYear, period, consolidationId }) =>
  httpPost({
    route: '/api/shared/load-all-form-data-for-consolidated',
    errorMessage: 'Failed to load all form data for consolidated entity',
    body: {
      taxYear,
      period,
      consolidationId,
    },
  });

export const performEntityDataCalculation = ({ taxYear, period, orgId, jurisdictionId }) =>
  httpPost({
    route: '/api/shared/entity-data-calculation',
    errorMessage: 'Entity data calculation failed',
    parseResponseErrorMessage: true,
    body: {
      taxYear,
      period,
      orgId,
      jurisdictionId,
    },
  });

export const fetchCustomerData = () =>
  httpGetAndParse({
    route: `/api/user-data`,
    errorMessage: 'Fetching customer data failed',
  });

export const updateLastLoginDate = clientUserId =>
  httpPutAndParse({
    route: '/api/update-last-login-date',
    errorMessage: 'Last login update failed',
    body: clientUserId,
  });

export const fetchPermissions = () =>
  httpGetAndParse({
    route: `/api/shared/user-permissions`,
    errorMessage: 'Fetching user permissions failed',
  });

export const fetchDataModelLinks = ({ taxYear, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/shared/data-model-links/${taxYear}/${jurisdictionId}`,
    errorMessage: 'Fetching data model links failed',
  });

export const signOut = () =>
  httpGet({
    route: `/api/logout`,
    errorMessage: 'Signing out failed',
  });

export const fetchJurisdictionsOptions = ({ taxYear, period }) =>
  httpGetAndParse({
    route: `/api/shared/jurisdictions/${taxYear}/${period}`,
    errorMessage: 'Fetching jurisdiction options failed',
  });

export const performTaxReturnCalculation = ({ taxYear, period, consolidationId, jurisdictionId }) =>
  httpPost({
    route: `/api/consolidations/perform-tax-return-calculation`,
    body: {
      taxYear,
      period,
      consolidationId,
      jurisdictionId,
    },
    errorMessage: 'Performing tax return calculation failed',
  });

export const performConsolidatedMembersTaxReturnCalculation = ({
  taxYear,
  period,
  consolidationId,
  jurisdictionId,
}) =>
  httpPost({
    route: `/api/consolidations/perform-consolidated-members-tax-return-calculation`,
    body: {
      taxYear,
      period,
      consolidationId,
      jurisdictionId,
    },
    errorMessage: 'Performing consolidated members tax return calculation failed',
  });

export const initiateEntityBatchCalculation = ({ taxYear, orgId, period }) =>
  httpPost({
    route: '/api/entities/initiate-batch-calculation',
    errorMessage: 'Initiating Entity batch calculation failed',
    body: {
      taxYear,
      period,
      orgId,
    },
  });

export const fetchJurisdictionsForWhichEntityIsLocked = ({ taxYear, period, orgId }) =>
  httpGetAndParse({
    route: `/api/entities/${taxYear}/${period}/${orgId}/locked-jurisdictions`,
    errorMessage: 'Fetching Jurisdiction for which entity is locked failed',
  });

export const initiateCalculationAllEntitiesForJurisdiction = ({
  taxYear,
  period,
  orgId,
  jurisdictionId,
}) =>
  httpPost({
    route: '/api/entities/initiate-calculation-all-entities-for-jurisdiction',
    errorMessage: 'Initiating all entities for jurisdiction calculation failed',
    body: {
      taxYear,
      period,
      orgId,
      jurisdictionId,
    },
  });

export const fetchIsPartnership = ({ taxYear, period, entityId, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/shared/${taxYear}/${period}/${entityId}/${jurisdictionId}/is-partnership`,
    errorMessage: 'Check is partnership failed',
  });

export const checkAuthorizationStatus = () =>
  httpGet({
    route: `/api/test-auth`,
    errorMessage: 'Checking Authorization Status Failed',
  });

export const fetchFormsByPeriod = ({
  taxYear,
  period,
  filingTypeId,
  businessEntityId,
  jurisdictionId,
}) =>
  httpGetAndParse({
    route: `/api/shared/forms-by-period/${taxYear}/${period}/${filingTypeId}/${businessEntityId}/${jurisdictionId}`,
    errorMessage: 'Fetching Forms for navigator failed',
  });

export const fetchInfoAccounts = ({ taxYear, period }) =>
  httpGetAndParse({
    route: `/api/admin/maintenance/info-accounts/${taxYear}/${period}`,
    errorMessage: 'Fetching info accounts failed',
  });
