import {
  httpGetAndParse,
  httpPostFile,
  httpDelete,
  httpPutFile,
} from '../../../utils/fetchService';

export const fetchBusinessOptions = ({ taxYear, period }) =>
  httpGetAndParse({
    route: `/api/tools/attachments/businesses/${taxYear}/${period}`,
    errorMessage: 'Fetching business options failed',
  });

export const fetchAttachmentDefinitions = ({ taxYear, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/tools/attachments/${taxYear}/${jurisdictionId}`,
    errorMessage: 'Fetching attachment definitions failed',
  });

export const createAttachment = ({ formData }) =>
  httpPostFile({
    route: '/api/tools/attachments',
    errorMessage: 'Creating pdf attachment failed',
    body: formData,
  });

export const deleteAttachment = ({ attachmentId }) =>
  httpDelete({
    route: `/api/tools/attachments/${attachmentId}`,
    error: 'Deleting attachment failed',
  });

export const updateAttachment = ({ formData }) =>
  httpPutFile({
    route: '/api/tools/attachments',
    errorMessage: 'Updating pdf attachment failed',
    body: formData,
  });
