import { filingTypes } from '../constants';

import { apiAction } from './apiAction';
import {
  fetchTaxYearsTypes,
  SELECT_TAX_YEAR,
  fetchPeriodsTypes,
  SELECT_PERIOD,
  SELECT_FILING_TYPE,
  fetchConsolidationsTypes,
  SELECT_CONSOLIDATION,
  SELECT_MAINTENANCE_CONSOLIDATION,
  fetchEntitiesTypes,
  fetchJurisdictionsOptionsTypes,
  SELECT_ENTITY,
  SELECT_JURISDICTION,
  fetchTrgReportsTypes,
  fetchCustomerDataTypes,
  fetchPermissionsTypes,
  SELECT_CLIENT_ID,
  loadAllFormDataForSeparateTypes,
  loadAllFormDataForConsolidatedTypes,
  fetchLockIndicatorTypes,
  entityDataCalculationTypes,
  fetchDataModelLinksTypes,
  CLEAR_DATA_MODEL_LINKS,
  signOutTypes,
  checkAuthorizationStatusTypes,
  refreshFederalProFormaDataTypes,
  performConsolidatedMembersTaxReturnCalculationTypes,
  initiateEntityBatchCalculationTypes,
  fetchJurisdictionsForWhichEntityIsLockedTypes,
  initiateCalculationAllEntitiesForJurisdictionTypes,
  fetchIsPartnershipTypes,
  fetchFormsByPeriodTypes,
  CLEAR_FORMS_BY_PERIOD,
  RESET_REDUX_STATE,
  updateLastLoginTypes,
} from './types';
import * as sharedApi from './api';
import {
  isFetchingPeriodsSelector,
  isFetchingConsolidationsSelector,
  isFetchingEntitiesSelector,
} from './selectors';

export const fetchTaxYears = apiAction({
  types: fetchTaxYearsTypes,
  apiAction: sharedApi.fetchTaxYears,
});
export const selectTaxYear = taxYear => ({
  type: SELECT_TAX_YEAR,
  payload: taxYear,
});

export const fetchPeriods = apiAction({
  types: fetchPeriodsTypes,
  apiAction: sharedApi.fetchPeriods,
  isFetchingSelector: isFetchingPeriodsSelector,
});
export const selectPeriod = period => ({
  type: SELECT_PERIOD,
  payload: period,
});

export const selectFilingType = filingTypeId => ({
  type: SELECT_FILING_TYPE,
  payload: filingTypeId,
});

export const fetchConsolidations = apiAction({
  types: fetchConsolidationsTypes,
  apiAction: sharedApi.fetchConsolidations,
  isFetchingSelector: isFetchingConsolidationsSelector,
});

export const selectConsolidation = consolidationId => ({
  type: SELECT_CONSOLIDATION,
  payload: consolidationId,
});

export const selectMaintenanceConsolidation = payload => ({
  type: SELECT_MAINTENANCE_CONSOLIDATION,
  payload,
});

export const fetchEntities = apiAction({
  types: fetchEntitiesTypes,
  apiAction: sharedApi.fetchEntities,
  isFetchingSelector: isFetchingEntitiesSelector,
});
export const selectEntity = entityId => ({
  type: SELECT_ENTITY,
  payload: entityId,
});

export const fetchTrgReports = apiAction({
  types: fetchTrgReportsTypes,
  apiAction: sharedApi.fetchTrgReports,
});

export const selectJurisdiction = jurisdictionId => ({
  type: SELECT_JURISDICTION,
  payload: jurisdictionId,
});
export const fetchJurisdictionsOptions = apiAction({
  types: fetchJurisdictionsOptionsTypes,
  apiAction: sharedApi.fetchJurisdictionsOptions,
});

export const fetchCustomerData = apiAction({
  types: fetchCustomerDataTypes,
  apiAction: sharedApi.fetchCustomerData,
});

export const fetchPermissions = apiAction({
  types: fetchPermissionsTypes,
  apiAction: sharedApi.fetchPermissions,
});

export const selectClientId = clientId => ({
  type: SELECT_CLIENT_ID,
  payload: clientId,
});

export const fetchLockIndicator = apiAction({
  types: fetchLockIndicatorTypes,
  apiAction: sharedApi.fetchLockIndicator,
});

export const loadAllFormDataForSeparate = apiAction(
  {
    types: loadAllFormDataForSeparateTypes,
    apiAction: sharedApi.loadAllFormDataForSeparate,
  },
  { successNotificationMessage: () => 'All Form Data for Separate Entity loaded successfully' },
);

export const loadAllFormDataForConsolidated = apiAction(
  {
    types: loadAllFormDataForConsolidatedTypes,
    apiAction: sharedApi.loadAllFormDataForConsolidated,
  },
  { successNotificationMessage: () => 'All Form Data for Consolidated Entity loaded successfully' },
);

export const performEntityDataCalculation = apiAction(
  {
    types: entityDataCalculationTypes,
    apiAction: sharedApi.performEntityDataCalculation,
  },
  {
    successNotificationMessage: () => 'Entity data calculation succeeded',
  },
);

export const updateLastLogin = apiAction({
  types: updateLastLoginTypes,
  apiAction: sharedApi.updateLastLoginDate,
});

export const fetchDataModelLinks = apiAction({
  types: fetchDataModelLinksTypes,
  apiAction: sharedApi.fetchDataModelLinks,
});

export const clearDataModelLinks = () => ({
  type: CLEAR_DATA_MODEL_LINKS,
});

export const signOut = apiAction({
  types: signOutTypes,
  apiAction: sharedApi.signOut,
});

export const checkAuthorizationStatus = apiAction({
  types: checkAuthorizationStatusTypes,
  apiAction: sharedApi.checkAuthorizationStatus,
});

export const performTaxReturnCalculation = apiAction(
  {
    types: refreshFederalProFormaDataTypes,
    apiAction: sharedApi.performTaxReturnCalculation,
  },
  { successNotificationMessage: () => 'Initiating tax return calculation succeeded' },
);

export const performConsolidatedMembersTaxReturnCalculation = apiAction(
  {
    types: performConsolidatedMembersTaxReturnCalculationTypes,
    apiAction: sharedApi.performConsolidatedMembersTaxReturnCalculation,
  },
  {
    successNotificationMessage: () =>
      'Initiating tax return calculation for consolidated members succeeded',
  },
);

export const initiateEntityBatchCalculation = apiAction(
  {
    types: initiateEntityBatchCalculationTypes,
    apiAction: sharedApi.initiateEntityBatchCalculation,
  },
  { successNotificationMessage: () => 'Initiating entity batch calculation succeeded' },
);

export const fetchJurisdictionsForWhichEntityIsLocked = apiAction({
  types: fetchJurisdictionsForWhichEntityIsLockedTypes,
  apiAction: sharedApi.fetchJurisdictionsForWhichEntityIsLocked,
});

export const initiateCalculationAllEntitiesForJurisdiction = apiAction(
  {
    types: initiateCalculationAllEntitiesForJurisdictionTypes,
    apiAction: sharedApi.initiateCalculationAllEntitiesForJurisdiction,
  },
  {
    successNotificationMessage: () =>
      'Initiating calculation all entities for jurisdiction succeeded',
  },
);

export const fetchIsPartnership = apiAction({
  types: fetchIsPartnershipTypes,
  apiAction: sharedApi.fetchIsPartnership,
});

export const resetState = () => ({
  type: RESET_REDUX_STATE,
});

export const fetchFormsByPeriod = apiAction({
  types: fetchFormsByPeriodTypes,
  apiAction: sharedApi.fetchFormsByPeriod,
});

export const clearFormsByPeriod = () => ({
  type: CLEAR_FORMS_BY_PERIOD,
});

export const setGlobalContext = ({
  taxYear,
  period,
  filingTypeId,
  entity,
  consolidationId,
  jurisdictionId,
  dispatch,
  globalContext,
}) => {
  if (taxYear && globalContext.taxYear !== taxYear) {
    dispatch(selectTaxYear(taxYear));
  }

  if (period && globalContext.period !== period) {
    dispatch(selectPeriod(period));
  }

  if (globalContext.filingTypeId !== filingTypeId) {
    dispatch(selectFilingType(filingTypeId));
  }

  if (filingTypeId === filingTypes.SEPARATE && globalContext.entityId !== entity) {
    dispatch(selectEntity(entity));
  }

  if (globalContext.jurisdictionId !== jurisdictionId) {
    dispatch(selectJurisdiction(jurisdictionId));
  }

  if (filingTypeId === filingTypes.CONSOLIDATED) {
    dispatch(selectConsolidation(consolidationId));
  }
};
