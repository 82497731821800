import React, { useMemo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch, useParams } from 'react-router-dom';
import { Routes } from '@common-packages/routes-definitions';
import { Button } from '@pwc/appkit-react';

import { useFetchCustomerData, useCsrfToken, useClientId } from './shared/store/hooks';
import {
  customerDataSelector,
  isFetchingCustomerDataErrorSelector,
  customerRoleIdSelector,
  clientOptionsSelector,
} from './shared/store/selectors';
import ErrorBoundary from './shared/error/ErrorBoundary.component';
import InitializationError from './shared/error/initializationError.component';
import config from './config';
import usePendo from './shared/hooks/usePendo.hook';
import SDKCustomSelect from './shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import ApplicationRoutes from './applicationRoutes.component';
import { errorMessages } from './shared/error/constants';
import AppLoading from './shared/displayComponents/appLoadingIndicator';
import styles from './clientRoot.module.scss';
import { errorNotification } from './shared/notification/store/actions';
import { updateLastLogin } from './shared/store/actions';

interface Client {
  id: string;
  name: string;
  shortName: string;
  gtwCLientKey: string;
  taxYearTtiTransmitterActive: string;
  expires: Date;
  activeFlag: string;
  clientUserId: string;
}

const ClientRootComponent = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const dispatch = useDispatch();
  const customerData = useSelector(customerDataSelector);
  const isFetchingCustomerDataError = useSelector(isFetchingCustomerDataErrorSelector);
  const customerRoleId = useSelector(customerRoleIdSelector);
  const clientOptions = useSelector(clientOptionsSelector);

  useCsrfToken();
  useFetchCustomerData();
  // Set the client context
  useClientId(clientId);

  const [selectedClientId, setSelectedClientId] = useState<string | null>(null);

  const isLoading = useMemo(
    () => !customerData.customerId || (!customerRoleId && !clientOptions?.length),
    [customerData, customerRoleId, clientOptions],
  );

  const customerDataRequestErrorMessage = isFetchingCustomerDataError
    ? errorMessages.CUSTOMER_DATA_ERROR_MESSAGE
    : '';

  // Set up Pendo snippet for the site
  usePendo(config.PENDO_KEY, customerData, isLoading, isFetchingCustomerDataError);

  const hasAccessToClients = clientOptions.length > 0;

  const clientChangeHandler = useCallback(
    (selected: { value: string }) => {
      if (selected) {
        setSelectedClientId(selected.value);
      }
    },
    [setSelectedClientId],
  );

  const dispatchUpdateLastLogin = (clientUserId: string | undefined) => {
    if (clientUserId) {
      dispatch(
        updateLastLogin({
          clientUserId,
        }),
      );
    }
  };

  const dispatchNotification = (message: string, duration: number = 5000) => {
    dispatch(
      errorNotification(message, {
        duration,
      }),
    );
  };

  //validate access based on active flag and expire date
  const validateUserAccessForClient = (filteredClient: Client | undefined) => {
    if (filteredClient) {
      if (filteredClient.activeFlag === 'N') {
        dispatchNotification(
          `Your account is no longer active for ${filteredClient.name}. To resolve this issue and regain access to your account, we kindly request you reach out to your Engagement Team Lead.
            They will assist you in addressing the situation promptly and provide guidance on the necessary steps to unlock your account.`,
          15000,
        );
        return;
      } else {
        if (new Date(filteredClient.expires) >= new Date()) {
          window.location.replace(`${config.BASE_PATH}/${selectedClientId}`);
        } else {
          dispatchNotification(
            'Your SLT account has expired, please contact your client specific engagement lead to have your account enabled.',
            10000,
          );
        }
        return;
      }
    }
  };

  const onGoButtonClick = useCallback(() => {
    if (customerData.clients) {
      const clientsList: Client[] = customerData.clients;
      const filteredClient: Client | undefined = clientsList.find(
        clnt => clnt.id === selectedClientId,
      );
      dispatchUpdateLastLogin(filteredClient?.clientUserId);
      validateUserAccessForClient(filteredClient);
    } else {
      dispatchNotification(
        'Error finding client, please contact your client specific engagement lead to have your account setup.',
      );
    }
  }, [selectedClientId]);

  if (isLoading) {
    return <AppLoading isLoading={isLoading} showNoClientAccessMessage={false} />;
  } else if (clientId) {
    // If the user has a client already selected (client identifier is present in the route), display the SLT app
    return (
      <ErrorBoundary>
        {isFetchingCustomerDataError ? (
          <InitializationError errorMessage={customerDataRequestErrorMessage} />
        ) : (
          <>
            <BrowserRouter basename={clientId}>
              <Switch>
                <Route exact path={Routes.MAIN} component={ApplicationRoutes} />
              </Switch>
            </BrowserRouter>
          </>
        )}
      </ErrorBoundary>
    );
  } else if (!clientId && hasAccessToClients) {
    // If the user does not have a client selected and has access to more than one client, display the client selection
    return (
      <ErrorBoundary>
        <div className="col-4 offset-4">
          <div className={styles.clientSelectionPanel}>
            <div className={styles.clientSelectionLogo}>
              <span className="a-pwc-logo-grid a-lg" />
            </div>
            <div className={styles.clientSelectionHeader}>
              <h3>State Lifecycle Tool</h3>
              <p>Hello. Great to see you again!</p>
            </div>
            <div>
              <div className="a-form-label">SELECT CLIENT</div>
              <SDKCustomSelect
                className="sdk-custom-select"
                placeholder="Select client"
                options={clientOptions}
                onChange={clientChangeHandler}
                value={selectedClientId}
                virtualized
                hideLabel
              />
            </div>
            <div className={styles.clientSelectionFooter}>
              <Button size="lg" disabled={!selectedClientId} onClick={onGoButtonClick}>
                GO
              </Button>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    );
  } else {
    // This technically should not be possible to reach, but displaying nothing in case it reaches here.
    return <></>;
  }
};

export default ClientRootComponent;
