import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Routes } from '@common-packages/routes-definitions';

import useBooleanState from '../../shared/hooks/useBooleanState.hook';
import Loading from '../../shared/displayComponents/loading.component';
import useReturns from '../../shared/hooks/useReturns.hook';
import { TaxReturnStatus } from '../../shared/enums';

import Header from './header';
import LoadingPage from './loadingPage/loadingPage.component';
import { useWizard } from './hooks';
import {
  AuthorizeStep,
  FederalReturnStep,
  ReviewStep,
  ValidationsStep,
  ViewStatusStep,
} from './steps';
import styles from './currentReturnFiling.module.scss';
import { WIZARD_MACHINE_STATES } from './constants';
import { StepStatus, WizardStepName } from './enums';

const CurrentReturnFiling = () => {
  const [viewDownloadsPanel, showPanel, hideDownloadsPanel] = useBooleanState(false);
  const { returnId } = useParams<{ returnId?: string }>();

  const {
    currentStep,
    wizard,
    wizardButtons,
    setIsCurrentStepReady,
    isWizardMachineReady,
    binder,
    authorization,
    validation,
    machineStateContext,
    setValidationContext,
    federalReturnResponse,
    setFederalReturnOption,
    setFederalSubmissionId,
    isCheckingTransmissionStatus,
    setTransmissionStatus,
    isReadyToCheckTransmissionStatus,
    handleCheckTransmissionStatus,
    ttiServiceRunningResult,
    isFederalReturnAttachmentValid,
    federalReturnAttachment,
    isFetchingTaxReturnOrTransmitterProviderTR,
    taxReturn,
    setSelectedTransmissionHistoryId,
    selectedTransmissionHistoryId,
    stateSubmissionId,
  } = useWizard({
    showPanel,
  });

  const { sltBinderId, ttiCaseData, ttiYearId, ttiBinderId, binderFilingId } = binder;

  const { isRunningValidationProcess, ttiGroupValidationData } = validation;

  const {
    isAuthorizationAndTransmissionAvailable,
    setAcceptedLegalVerbiage,
    isLegalVerbiageAccepted,
    setSignature,
    signature,
    setShouldIgnoreErrors,
    shouldIgnoreErrors,
  } = authorization;

  const { federalReturn } = federalReturnResponse;

  useReturns({ routePath: Routes.currentReturnFilingV2.MAIN });

  useEffect(() => {
    if (currentStep !== WIZARD_MACHINE_STATES.STEP_2_VALIDATE) {
      hideDownloadsPanel();
    }
  }, [currentStep, hideDownloadsPanel]);

  const isReturnRequired = Boolean(
    federalReturn?.isAsFiledFederalReturnOptionAvailable ||
      federalReturn?.isProformaFederalReturnOptionAvailable,
  );
  const isFederalFileAvailable = Boolean(federalReturn?.isFileAvailable);
  const isValidationRunning = useMemo(
    () =>
      Boolean(
        isRunningValidationProcess ||
          machineStateContext.eFileSteps[WizardStepName.STEP_2_VALIDATE] === StepStatus.INITIATED,
      ),
    [isRunningValidationProcess, machineStateContext.eFileSteps],
  );

  const page = useMemo(() => {
    if (!isFederalReturnAttachmentValid) {
      return (
        <LoadingPage
          header="Error"
          details={[
            'The return definition requires additional setup for the federal return options.',
            ' Contact support for assistance using the steps in the Resource Center.',
          ]}
          imgPath="/assets/efile-config-error.png"
        />
      );
    }

    switch (currentStep) {
      case WIZARD_MACHINE_STATES.STEP_1_CHOOSE_FEDERAL_RETURN: {
        return (
          <FederalReturnStep
            setFederalReturnOption={setFederalReturnOption}
            setFederalSubmissionId={setFederalSubmissionId}
            federalReturnOption={machineStateContext.federalReturn.federalReturnOption}
            filingFormName={federalReturn.filingFormName}
            wizardStateMachineSetFederalSubmissionId={
              machineStateContext.federalReturn.submissionId
            }
            efSubmissionId={machineStateContext.eFileService.submissionId}
            selectedTtiSubmissionId={machineStateContext.stateReturn.submissionId}
            returnId={sltBinderId}
          />
        );
      }
      case WIZARD_MACHINE_STATES.STEP_2_VALIDATE: {
        return (
          <ValidationsStep
            viewDownloadsPanel={viewDownloadsPanel}
            hideDownloadsPanel={hideDownloadsPanel}
            isFederalFileAvailable={isFederalFileAvailable}
            isValidationRunning={isValidationRunning}
            setValidationContext={setValidationContext}
            eFileServiceSubmissionId={machineStateContext.eFileService.submissionId}
            federalSubmissionId={machineStateContext.federalReturn.submissionId}
            ttiYearId={ttiYearId ?? null}
            ttiCaseId={ttiCaseData?.ttiCaseId || null}
            ttiBinderId={ttiBinderId ?? null}
            sltBinderId={sltBinderId}
            isReturnRequired={isReturnRequired}
            validationStatus={machineStateContext.eFileSteps[WizardStepName.STEP_2_VALIDATE]}
            ttiGroupValidationData={ttiGroupValidationData}
            machineStateContext={machineStateContext}
            isFetchingTaxReturnOrTransmitterProviderTR={isFetchingTaxReturnOrTransmitterProviderTR}
          />
        );
      }
      case WIZARD_MACHINE_STATES.STEP_3_REVIEW: {
        return (
          <ReviewStep
            stateSubmissionId={machineStateContext.stateReturn.submissionId}
            federalReturnOption={machineStateContext.federalReturn.federalReturnOption}
            federalSubmissionId={machineStateContext.federalReturn.submissionId}
            isFetchingTaxReturnOrTransmitterProviderTR={isFetchingTaxReturnOrTransmitterProviderTR}
          />
        );
      }
      case WIZARD_MACHINE_STATES.STEP_4_AUTHORIZE_AND_TRANSMIT: {
        return (
          <AuthorizeStep
            efServiceSubmissionId={machineStateContext.eFileService.submissionId}
            setIsCurrentStepReady={setIsCurrentStepReady}
            isReadyForAuthorization={Boolean(isAuthorizationAndTransmissionAvailable)}
            setAcceptedLegalVerbiage={setAcceptedLegalVerbiage}
            isLegalVerbiageAccepted={isLegalVerbiageAccepted}
            setSignature={setSignature}
            signature={signature}
            setShouldIgnoreErrors={setShouldIgnoreErrors}
            shouldIgnoreErrors={shouldIgnoreErrors}
            ttiYearId={ttiYearId}
            ttiCaseId={ttiCaseData?.ttiCaseId}
            ttiBinderId={ttiBinderId}
            authorizationStatus={
              machineStateContext.eFileSteps[WizardStepName.STEP_4_AUTHORIZE_AND_TRANSMIT]
            }
            isFetchingTaxReturnOrTransmitterProviderTR={isFetchingTaxReturnOrTransmitterProviderTR}
            federalReturnAttachment={federalReturnAttachment}
            federalSubmissionId={machineStateContext.federalReturn.submissionId}
            federalReturnOption={machineStateContext.federalReturn.federalReturnOption}
          />
        );
      }
      case WIZARD_MACHINE_STATES.STEP_5_VIEW_STATUS: {
        return (
          <ViewStatusStep
            step5Status={machineStateContext.eFileSteps[WizardStepName.STEP_5_VIEW_STATUS]}
            isCheckingTransmissionStatus={isCheckingTransmissionStatus}
            stateSubmissionId={machineStateContext.stateReturn.submissionId}
            federalReturnOption={machineStateContext.federalReturn.federalReturnOption}
            federalSubmissionId={machineStateContext.federalReturn.submissionId}
            setTransmissionStatus={setTransmissionStatus}
            isReadyToCheckTransmissionStatus={isReadyToCheckTransmissionStatus}
            checkTransmissionStatus={handleCheckTransmissionStatus}
            isCheckTransmissionStatusNotRunPreviously={
              machineStateContext.eFileSteps[WizardStepName.STEP_5_VIEW_STATUS] === null
            }
            binderFilingId={binderFilingId}
            selectedTransmissionHistoryId={selectedTransmissionHistoryId}
            isFetchingTaxReturnOrTransmitterProviderTR={isFetchingTaxReturnOrTransmitterProviderTR}
            taxReturn={taxReturn}
            setSelectedTransmissionHistoryId={setSelectedTransmissionHistoryId}
          />
        );
      }
      default: {
        return null;
      }
    }
  }, [
    setFederalReturnOption,
    setFederalSubmissionId,
    setValidationContext,
    setIsCurrentStepReady,
    setAcceptedLegalVerbiage,
    setSignature,
    setShouldIgnoreErrors,
    setTransmissionStatus,
    handleCheckTransmissionStatus,
    currentStep,
    federalReturn,
    viewDownloadsPanel,
    hideDownloadsPanel,
    isFederalFileAvailable,
    ttiYearId,
    ttiCaseData?.ttiCaseId,
    ttiBinderId,
    sltBinderId,
    isReturnRequired,
    isAuthorizationAndTransmissionAvailable,
    isLegalVerbiageAccepted,
    signature,
    shouldIgnoreErrors,
    isCheckingTransmissionStatus,
    isReadyToCheckTransmissionStatus,
    binderFilingId,
    isValidationRunning,
    ttiGroupValidationData,
    isFederalReturnAttachmentValid,
    machineStateContext,
    federalReturnAttachment,
    selectedTransmissionHistoryId,
    isFetchingTaxReturnOrTransmitterProviderTR,
    taxReturn,
  ]);

  return (
    <>
      <Header
        stateSubmissionId={stateSubmissionId}
        federalSubmissionId={machineStateContext.federalReturn.submissionId}
        federalReturnOption={machineStateContext.federalReturn.federalReturnOption}
        isWizardMachineReady={isWizardMachineReady}
        returnId={returnId}
      />
      {isWizardMachineReady ? (
        <>
          {isFetchingTaxReturnOrTransmitterProviderTR ||
          ttiServiceRunningResult?.isTtiServiceRunning ||
          (taxReturn?.returnStatus !== TaxReturnStatus.NOT_STARTED && taxReturn?.isTTIReadOnly) ? (
            <>
              <div className={`row ${styles.currentReturnFilingWrapper}`}>
                {wizard}
                {page}
              </div>
              {isFederalReturnAttachmentValid && wizardButtons}
            </>
          ) : (
            <LoadingPage
              header="E-File Transmitter Updating"
              details={[
                'Updates to the e-File transmitter are taking place.',
                'E-File features will be unavailable during this time.',
              ]}
              imgPath="/assets/efile-config-error.png"
            />
          )}
        </>
      ) : (
        <Loading isLoading />
      )}
    </>
  );
};

export default CurrentReturnFiling;
