import fetchServiceCreator, { handleErrors, parseAsJson, postHeaders } from '@tls/ui-request';
import { HttpMethods } from '@common-packages/shared-constants';
import { StatusCodes } from 'http-status-codes';

import config from '../config';
import store from '../store';
import { csrfTokenSelector } from '../shared/store/auth';
import { showPermissionModal } from '../shared/permissionModal/store/actions';
import { clientIdSelector } from '../shared/store/context';

import { handleAccessTokenExpirationDate } from './tokenUtils';

const replacer = (key, value) => (typeof value === 'undefined' ? null : value);

const fetchService = fetchServiceCreator(config.BASE_PATH);

const fetchServiceWrapper = (route, options) => {
  const storeState = store.getState();
  const csrfToken = csrfTokenSelector(storeState);
  const clientId = clientIdSelector(storeState);
  const commonHeaders = { clientId };

  return fetchService(route, {
    ...options,
    headers: {
      ...commonHeaders,
      ...options.headers,
      'csrf-token': csrfToken, // it has to be a specific header name for the csurf to get the data
      Authorization: `Bearer ${localStorage.getItem('id_token')}`,
    },
  });
};

export const httpFetch = method => async ({
  route,
  errorMessage,
  body,
  parseResponseErrorMessage = false,
}) =>
  fetchServiceWrapper(route, {
    method,
    headers: method.toUpperCase() === HttpMethods.GET ? {} : postHeaders,
    ...(body ? { body: JSON.stringify(body, replacer) } : {}),
  })
    .then(handleErrors({ errorMessage, parseResponseErrorMessage }))
    .then(handleAccessTokenExpirationDate)
    .then(handleRefreshAuthToken)
    .catch(error => {
      if (error.isFetchingError && error.status === StatusCodes.NOT_FOUND) {
        store.dispatch(
          showPermissionModal({
            confirmText: null,
            dismissText: 'Close',
            title: 'Access denied',
            text:
              'You currently do not have permission to complete this task, contact your system administrator if you need access',
          }),
        );
      }
      throw error;
    });

export const httpFetchFile = method => async ({
  route,
  errorMessage = '',
  body,
  parseResponseErrorMessage = false,
}) =>
  fetchServiceWrapper(route, {
    method,
    body,
  }).then(handleErrors({ errorMessage, parseResponseErrorMessage }));

const handleRefreshAuthToken = response => {
  const refreshToken = localStorage.getItem('refresh_token');

  if (refreshToken) {
    const expirationDate = new Date(localStorage.getItem('expiration_date'));
    const clockTimestamp = Math.floor(Date.now() / 1000);
    const expiresIn = Math.floor(new Date(expirationDate) / 1000);
    const secondsToRefresh = expiresIn - clockTimestamp;
    const jwtRefreshTime = config.AUTH.JWT_REFRESH_TIME;
    if (secondsToRefresh > 0 && secondsToRefresh <= jwtRefreshTime) {
      // Remove the refresh token from local storage so any subsequent requests won't trigger another call to refresh until we have a new token
      localStorage.removeItem('refresh_token');

      httpPostAndParse({
        route: '/api/refresh_token',
        body: { refreshToken },
        parseResponseErrorMessage: false,
        // eslint-disable-next-line camelcase
      }).then(({ token: { id_token, refresh_token, expires_in } = {} }) => {
        // update local storage token information for the user
        localStorage.setItem('id_token', id_token);
        localStorage.setItem('refresh_token', refresh_token);
        localStorage.setItem(
          'expiration_date',
          // eslint-disable-next-line camelcase
          new Date(new Date().getTime() + expires_in * 1000).toString(),
        );
      });
    }
  }

  return response;
};

export const httpGet = httpFetch(HttpMethods.GET);
export const httpPost = httpFetch(HttpMethods.POST);
export const httpPut = httpFetch(HttpMethods.PUT);
export const httpDelete = httpFetch(HttpMethods.DELETE);
export const httpPostFile = httpFetchFile(HttpMethods.POST);
export const httpPutFile = httpFetchFile(HttpMethods.PUT);

export const httpGetAndParse = params => httpGet(params).then(parseAsJson);
export const httpPostAndParse = params => httpPost(params).then(parseAsJson);
export const httpPutAndParse = params => httpPut(params).then(parseAsJson);
export const httpDeleteAndParse = params => httpDelete(params).then(parseAsJson);
export const httpPostFileAndParse = params => httpPostFile(params).then(parseAsJson);
export const httpGetBlob = params => httpGet(params).then(response => response.blob());

export const httpPostAndGetBlob = params => httpPost(params).then(response => response.blob());

export const encodeParam = uriParam => encodeURIComponent(uriParam);
