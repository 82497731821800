import React from 'react';
import { useSelector } from 'react-redux';

import {
  globalContextSelector,
  isFetchingGlobalContextSelector,
  periodNameSelector,
  businessEntityNameSelector,
  jurisdictionDescriptionSelector,
  isFetchingJurisdictionsSelector,
  filingTypeDescriptionSelector,
} from '../../../../shared/store/selectors';
import { isFetchingFilingStatesSelector } from '../../../../filingStates/store/selectors';
import Loading from '../../../../shared/displayComponents/loading.component';
import { StepTabHeader } from '../stepTab';
import { FederalReturnOption } from '../../../../shared/enums/eFileV2';
import { WizardStateMachineSetFederalSubmissionId } from '../../wizardStateMachine/types';
import { useGetFederalReturn, useGetManualFederalProformaSubmissionIdOptions } from '../../hooks';
import { GlobalContext, SubmissionId } from '../../../../../../common/types';

import styles from './federalReturnStep.module.scss';
import ReturnRequirements from './returnRequirements/returnRequirements.component';

interface FederalReturnStepProps {
  federalReturnOption: FederalReturnOption | null;
  setFederalReturnOption: (federalReturnOption: FederalReturnOption | null) => void;
  setFederalSubmissionId: (submissionId: WizardStateMachineSetFederalSubmissionId) => void;
  filingFormName: string | null;
  wizardStateMachineSetFederalSubmissionId: WizardStateMachineSetFederalSubmissionId | null;
  efSubmissionId: SubmissionId | null;
  selectedTtiSubmissionId: string | null;
  returnId: number;
}

const FederalReturnStep = ({
  setFederalReturnOption,
  setFederalSubmissionId,
  federalReturnOption,
  filingFormName,
  wizardStateMachineSetFederalSubmissionId,
  efSubmissionId,
  selectedTtiSubmissionId,
  returnId,
}: FederalReturnStepProps) => {
  const globalContext: GlobalContext = useSelector(globalContextSelector);
  const isFetchingGlobalContext = useSelector(isFetchingGlobalContextSelector);

  const periodName = useSelector(periodNameSelector);
  const businessEntityName = useSelector(businessEntityNameSelector);
  const jurisdictionDescription = useSelector(jurisdictionDescriptionSelector);
  const isFetchingJurisdictions = useSelector(isFetchingJurisdictionsSelector);

  const isFetchingFilingStates = useSelector(isFetchingFilingStatesSelector);
  const filingType = useSelector(filingTypeDescriptionSelector);

  const { federalReturn, isFetchingFederalReturn } = useGetFederalReturn();
  const {
    manualFederalProformaSubmissionIdOptions,
    isFetchingFederalProformaSubmissionIdOption,
  } = useGetManualFederalProformaSubmissionIdOptions();

  return (
    <>
      <StepTabHeader
        title="Filing for the following"
        className={styles.stepTabHeaderContainer}
        headerTitleClassName={styles.headerTitle}
      >
        <div className={`row grid-row ${styles.filingHeader}`}>
          <div className={styles.contextCol}>
            <span>
              <b>Tax Year: </b>
              <Loading small isLoading={isFetchingGlobalContext}>
                {globalContext.taxYear}
              </Loading>
            </span>
            <span>
              <b>Period: </b>
              <Loading small isLoading={isFetchingGlobalContext}>
                {periodName}
              </Loading>
            </span>
            <div>
              <span>
                <b>Entity: </b>
                <Loading small isLoading={isFetchingGlobalContext}>
                  {businessEntityName}
                </Loading>
              </span>
            </div>
          </div>
          <div className={styles.contextCol}>
            <span>
              <b>Filing Type: </b>
              <Loading small isLoading={isFetchingGlobalContext}>
                {filingType}
              </Loading>
            </span>
            <span className={styles.jurisdiction}>
              <b>Jurisdiction: </b>
              <Loading
                small
                isLoading={
                  isFetchingGlobalContext || isFetchingFilingStates || isFetchingJurisdictions
                }
              >
                {jurisdictionDescription?.toLowerCase()}
              </Loading>
            </span>
            <span>
              <b>Return: </b>
              <Loading small isLoading={isFetchingFederalReturn}>
                {filingFormName}
              </Loading>
            </span>
          </div>
        </div>
      </StepTabHeader>
      <div className={styles.returnRequirementsContainer}>
        <Loading
          isLoading={
            !globalContext.isReady ||
            isFetchingFederalReturn ||
            isFetchingFederalProformaSubmissionIdOption
          }
        >
          <ReturnRequirements
            setFederalReturnOption={setFederalReturnOption}
            setFederalSubmissionId={setFederalSubmissionId}
            federalReturnOption={federalReturnOption}
            wizardStateMachineSetFederalSubmissionId={wizardStateMachineSetFederalSubmissionId}
            federalReturn={federalReturn}
            manualFederalProformaSubmissionIdOptions={
              manualFederalProformaSubmissionIdOptions || []
            }
            efSubmissionId={efSubmissionId}
            selectedTtiSubmissionId={selectedTtiSubmissionId}
            returnId={returnId}
          />
        </Loading>
      </div>
    </>
  );
};

export default FederalReturnStep;
